import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {For} from 'babel-plugin-jsx-control-statements'
import {useState} from 'react'
import theme from '../../../theme/theme'
import {media} from '../../../utils'
import ChevronIcon from '../../../assets/icons/chevron.svg'
import ColorFeatherCheckIcon from '../../atoms/Icons/ColorFeatherCheckIcon'
import Typography from '../../atoms/Typography'


const StyledDetailsContainer = styled.div`
  margin: 3rem 1.5rem;
  border: 1px solid ${theme.colors.gray89};
  background-color: ${theme.colors.gray98};
  padding: 3rem 2rem;

  ${media.up('mobile')(css`
    display: none;
  `)}
`

const StyledDetailsHeader = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid ${theme.colors.border};
  padding-bottom: 2.5rem;
`

const StyledDetails = styled.details`
  padding-top: 2rem;
`

const StyledSummary = styled.summary`
  display: flex;
  cursor: pointer;

  ::-webkit-details-marker {
    display: none;
  }
`

const StyledChevronContainer = styled.div`
  display: flex;
  align-items: center;
  transform: rotate(${({rotate}) => rotate ? '90deg' : '270deg'});
  margin-left: 2rem;
`

const StyledDetailsPoint = styled.div`
  display: flex;
  align-items: center;
  margin: 1.5rem 0;
`

const StyledCheck = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${({active}) => active ? `1px solid ${theme.colors.green}` : `1px solid ${theme.colors.silver}`};
  border-radius: 50%;
  width: 1.75rem;
  height: 1.75rem;
`

const StyledText = styled.span`
  margin-left: 1.5rem;
`


const DetailsSection = ({tiers}) => {
  const [open, setOpen] = useState(false)

  const handleOpen = (e, idx) => {
    e.preventDefault()
    if (open !== idx) {
      setOpen(idx)
    } else {
      setOpen(false)
    }
  }

  return (
    <>
      <StyledDetailsContainer>
        <StyledDetailsHeader>
          <Typography variant="h2" type="h5" color="primary">{tiers.sales.title}</Typography>
          <Typography variant="h3" type="h3">{tiers.sales.focus}</Typography>
        </StyledDetailsHeader>
        <StyledDetails onClick={(e) => handleOpen(e, 1)} open={open === 1}>
          <StyledSummary>
            <Typography variant="span" type="h6">{tiers.sales.accordionSummary}</Typography>
            <StyledChevronContainer rotate={open !== 1}>
              <ChevronIcon size="sm" noFill />
            </StyledChevronContainer>
          </StyledSummary>
          <For each="point" of={tiers.sales.points} index="idx">
            <StyledDetailsPoint key={idx}>
              <StyledCheck active>
                <ColorFeatherCheckIcon color={theme.colors.green} />
              </StyledCheck>
              <StyledText>
                {point.point}
              </StyledText>
            </StyledDetailsPoint>
          </For>
        </StyledDetails>
      </StyledDetailsContainer>
      <StyledDetailsContainer>
        <StyledDetailsHeader>
          <Typography variant="h2" type="h5" color="primary">{tiers.automation.title}</Typography>
          <Typography variant="h3" type="h3">{tiers.automation.focus}</Typography>
        </StyledDetailsHeader>
        <StyledDetails onClick={(e) => handleOpen(e, 2)} open={open === 2}>
          <StyledSummary>
            <Typography variant="span" type="h6">{tiers.automation.accordionSummary}</Typography>
            <StyledChevronContainer rotate={open !== 2}>
              <ChevronIcon size="sm" noFill />
            </StyledChevronContainer>
          </StyledSummary>
          <For each="point" of={tiers.automation.points} index="idx">
            <StyledDetailsPoint key={idx}>
              <StyledCheck active>
                <ColorFeatherCheckIcon color={theme.colors.green} />
              </StyledCheck>
              <StyledText>
                {point.point}
              </StyledText>
            </StyledDetailsPoint>
          </For>
        </StyledDetails>
      </StyledDetailsContainer>
      <StyledDetailsContainer>
        <StyledDetailsHeader>
          <Typography variant="h2" type="h5" color="primary">{tiers.nzu.title}</Typography>
          <Typography variant="h3" type="h3">{tiers.nzu.focus}</Typography>
        </StyledDetailsHeader>
        <StyledDetails onClick={(e) => handleOpen(e, 3)} open={open === 3}>
          <StyledSummary>
            <Typography variant="span" type="h6">{tiers.nzu.accordionSummary}</Typography>
            <StyledChevronContainer rotate={open !== 3}>
              <ChevronIcon size="sm" noFill />
            </StyledChevronContainer>
          </StyledSummary>
          <For each="point" of={tiers.nzu.points} index="idx">
            <StyledDetailsPoint key={idx}>
              <StyledCheck active>
                <ColorFeatherCheckIcon color={theme.colors.green} />
              </StyledCheck>
              <StyledText>
                {point.point}
              </StyledText>
            </StyledDetailsPoint>
          </For>
        </StyledDetails>
      </StyledDetailsContainer>
    </>
  )
}

DetailsSection.propTypes = {
  tiers: PropTypes.object.isRequired,
}

export default DetailsSection
