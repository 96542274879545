import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {media} from '../../../utils'


const StyledPillarCardContainer = styled.div`
  margin: 0 10rem 10rem 14rem;

  ${media.up('mobile')(css`
    &:nth-child(even) {
      margin: 0 14rem 10rem 10rem;
      & > :first-child {
        & > :first-child {
          order: 1;
        }
      }
    }
  `)}

  ${media.down('mobile')(css`
    margin: 3rem 1.5rem;
  `)}
`

const PillarCardContainer = ({children, ...props}) => {
  return (
    <StyledPillarCardContainer {...props}>
      {children}
    </StyledPillarCardContainer>
  )
}

PillarCardContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PillarCardContainer
