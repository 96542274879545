import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {media, convertMDContent} from '../../../utils'
import Typography from '../../atoms/Typography'
import Image from '../../atoms/Image'
import SecondaryButton from '../../atoms/SecondaryButton'


const StyledPillarCard = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;

  ${media.down('mobile')(css`
  grid-template-columns: auto;
`)}
`

const StyledTitle = styled(Typography)`
  padding-bottom: 0.5rem;
  color: ${({theme}) => theme.colors.primary};
`

const StyledDescription = styled(Typography)`
  padding: 1.5rem 0 2rem;
`

/* eslint-disable max-len */
const StyledContent = styled(Typography)`
  ul {
    padding-left: 1rem;

    li {
      &::before {
        padding-right: 0.5rem;
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.121' height='9.373' viewBox='0 0 13.121 9.373'%3E%3Cpath d='M17,9,9.438,16.563,6,13.125' transform='translate(-4.939 -7.939)' fill='none' stroke='%23e02b36' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3C/svg%3E%0A");
      }
    }
  }
`
/* eslint-enable max-len */

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledImage = styled(Image)`
  display: block;
  margin-bottom: 3rem;
  max-width: 32rem;
  max-height: 32rem;

  ${media.down('mobile')(css`
    margin-top: 3rem;
  `)}
`

const PillarCard = ({title, subtitle, description, content, featuredImage, action, onClick, ...props}) => {
  const handleClick = () => {
    if (onClick) {
      return onClick()
    }
  }

  return (
    <StyledPillarCard {...props}>
      <div>
        <StyledTitle variant="h2" type="h5">{title}</StyledTitle>
        <Typography variant="h2" type="h5">{subtitle}</Typography>
        <StyledDescription variant="p">{description}</StyledDescription>
        <StyledContent type="div" dangerouslySetInnerHTML={{__html: convertMDContent(content)}} />
      </div>
      <StyledColumn>
        <StyledImage
            image={featuredImage.image}
            alt={featuredImage.alt}
        />
        <SecondaryButton onClick={handleClick}>{action.text}</SecondaryButton>
      </StyledColumn>
    </StyledPillarCard>
  )
}

PillarCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  featuredImage: PropTypes.object.isRequired,
  action: PropTypes.object.isRequired,
  onClick: PropTypes.func,
}

export default PillarCard
