import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {media} from '../../../utils'
import Image from '../../atoms/Image'


const StyledImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-row: 1 / 5;
  align-items: center;
  text-align: center;
  color: grey;
  font-size: 1rem;

  ${media.down('mobile')(css`
    grid-column: 1 / 3;
    grid-row: 3 / 4;
    order: 2;
  `)}
`

const StyledImg = styled(Image)`
  display: block;
  max-width: 32rem;
  max-height: 32rem;

  ${media.down('mobile')(css`
    margin-top: 3.125rem;
    max-width: 100%;
  `)}
`

const ImageContainer = ({image, ...props}) => {
  return (
    <StyledImageContainer {...props}>
      <StyledImg image={image.image} alt={image.alt} loading="eager" />
    </StyledImageContainer>
  )
}

ImageContainer.propTypes = {
  image: PropTypes.object.isRequired,
}

export default ImageContainer
