import React, {useState} from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {For, Choose, When, Otherwise} from 'babel-plugin-jsx-control-statements'
import settings from '../../../../content/settings/general.json'
import countries from '../../../../content/settings/countries.json'
import {media, sendForm, convertMDContent} from '../../../utils'
import Typography from '../../atoms/Typography'
import SubmitButton from '../../inputs/SubmitButton'
import TextInput from '../../inputs/TextInput'
import TextArea from '../../inputs/TextArea'
import PhoneInput from '../../inputs/PhoneInput'
import RadioButton from '../../inputs/RadioButton'


const StyledFormTitle = styled.div`
  padding: 0 8rem;

  ${media.down('mobile')(css`
    padding: 0;
  `)}
`

const StyledSubtitleContainer = styled.div`
  padding-bottom: 1rem;
`

const StyledFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5rem 0;
`

const StyledMessage = styled.span`
  opacity: 0.5;
  padding: 3rem 0 2.5rem 0;
  line-height: 3.75rem;
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 0 8.125rem;
  padding-top: 3rem;
  width: 100%;
  max-height: 35rem;
  overflow: scroll;

  ${media.down('mobile')(css`
    padding: 0;
  `)}
`

const StyledScrolledContent = styled.div`
  border-bottom: 1px solid ${({theme}) => theme.colors.lightGray};
  padding-bottom: 1rem;
  max-height: 35rem;
  overflow: scroll;
`

const StyledInputContainer = styled.div`
  padding-bottom: 1rem;
`

const StyledTitle = styled(Typography)`
  padding: 2rem 0 1rem;
`

const StyledRadioButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 1rem;
  padding-left: 0.25rem;

  ${media.down('mobile')(css`
    flex-direction: column;
    padding-bottom: 0;
  `)}
`

const StyledRadioButtonContainer = styled.div`
  padding-bottom: 1rem;
  min-width: 33.33%;

  ${media.down('mobile')(css`
    padding-bottom: 0;
    min-width: auto;
  `)}
`

const StyledRadioButton = styled(RadioButton)`
  margin: 0 auto 1rem 0;

  ${media.down('mobile')(css`
    padding-left: 0;
    margin-bottom: 1.5rem;
  `)}
`

const StyledTextContainer = styled.div`
  a {
    text-decoration: underline;
    color: ${({theme}) => theme.colors.secondary};
  }
`

const StyledButton = styled(SubmitButton)`
  margin-top: 0.5rem;

  ${media.down('mobile')(css`
    height: 4.5rem;
  `)}
`

const SuiteForm = ({lang, pillar, formContent, ...props}) => {
  const initialData = {
    'name': '',
    'company': '',
    'email': '',
    'prefix': countries.countries[56].dial_code,
    'number': '',
    'type': formContent?.applicationType[pillar],
    'count': '',
    'message': '',
    'bot-field': '',
  }

  const {form} = settings[lang]
  const [formData, setFormData] = useState(initialData)
  const [showMessage, setShowMessage] = useState(false)

  const handleChange = (name, value) => setFormData((prevValues) => ({...prevValues, [name]: value}))

  const handleSubmit = async (e) => {
    e.preventDefault()

    await sendForm({'form-name': 'suite', ...formData})
      .then(() => {
        setShowMessage(true)
      })
      .catch((error) => console.error(error))
  }

  return (
    <>
      <StyledFormTitle>
        <Typography type="h6" align="center">
          {formContent.title}
        </Typography>
      </StyledFormTitle>
      <StyledFormContainer {...props}>
        <Choose>
          <When condition={showMessage}>
            <StyledMessage>{form.successMessage}</StyledMessage>
          </When>
          <Otherwise>
            <StyledForm
                name="suite"
                method="post"
                action="/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
            >
              <StyledScrolledContent>
                <StyledSubtitleContainer {...props}>
                  <Typography variant="h3" type="h6">{formContent.subtitle}</Typography>
                </StyledSubtitleContainer>
                <StyledInputContainer>
                  <input type="hidden" name="form-name" value="suite" />
                  <TextInput
                      name="name"
                      label={formContent.name}
                      value={formData?.name}
                      onChange={handleChange}
                      required
                  />
                </StyledInputContainer>
                <StyledInputContainer>
                  <TextInput
                      name="company"
                      label={formContent.company}
                      value={formData?.company}
                      onChange={handleChange}
                      required
                  />
                </StyledInputContainer>
                <StyledInputContainer>
                  <TextInput
                      name="email"
                      type="email"
                      label={formContent.email}
                      value={formData?.email}
                      onChange={handleChange}
                      required
                  />
                </StyledInputContainer>
                <StyledInputContainer>
                  <PhoneInput
                      label={formContent.phoneNumber}
                      prefixName="prefix"
                      numberName="number"
                      onChange={handleChange}
                      NumberValue={formData?.number}
                      prefixValue={formData?.prefix}
                  />
                </StyledInputContainer>
                <StyledTitle variant="h3" type="h6">{formContent.applicationTypeText}</StyledTitle>
                <StyledRadioButtonsContainer>
                  <For each="applicationType" of={formContent.applicationType}>
                    <StyledRadioButtonContainer key={applicationType}>
                      <StyledRadioButton
                          name="type"
                          value={applicationType}
                          onChange={(name, value) => {
                            handleChange(name, value)
                          }}
                          checked={formData.type === applicationType}
                      />
                    </StyledRadioButtonContainer>
                  </For>
                </StyledRadioButtonsContainer>
                <StyledTitle variant="h3" type="h6">{formContent.countText}</StyledTitle>
                <StyledInputContainer>
                  <TextInput
                      name="count"
                      label={formContent.count}
                      value={formData?.count}
                      onChange={handleChange}
                  />
                </StyledInputContainer>
                <StyledTitle variant="h3" type="h6">{formContent.messageText}</StyledTitle>
                <StyledInputContainer>
                  <TextArea
                      name="message"
                      label={formContent.message}
                      value={formData?.message}
                      onChange={handleChange}
                  />
                </StyledInputContainer>
              </StyledScrolledContent>
              <StyledTextContainer>
                <Typography
                    thin
                    align="center"
                    dangerouslySetInnerHTML={{__html: convertMDContent(form.confirmation)}}
                />
              </StyledTextContainer>
              <TextInput
                  hidden
                  name="bot-field"
                  value={formData?.['bot-field']}
                  onChange={handleChange}
              />
              <StyledInputContainer>
                <StyledButton value={form.confirmButton} />
              </StyledInputContainer>
            </StyledForm>
          </Otherwise>
        </Choose>
      </StyledFormContainer>
    </>

  )
}

SuiteForm.propTypes = {
  lang: PropTypes.string.isRequired,
  pillar: PropTypes.number,
  formContent: PropTypes.object.isRequired,
}

export default SuiteForm
