import PropTypes from 'prop-types'
import {If, For} from 'babel-plugin-jsx-control-statements'
import styled, {css} from 'styled-components'
import {isEmpty} from 'lodash-es'
import {PdfIcon} from '../../../assets/icons'
import {media} from '../../../utils'
import theme from '../../../theme/theme'
import Typography from '../../atoms/Typography'
import BaseLink from '../../atoms/BaseLink'


const StyledDownloadContainer = styled.div`
  margin: 4rem 0;
  padding: 2rem 6.125rem;

  ${({dark}) => dark && css`
    background-color: ${theme.colors.gray98};
  `}

  ${media.down('mobile')(css`
    display: none;

    ${({mobileVisible}) => mobileVisible && css`
      display: block;
      margin: 0;
      padding: 2rem 1.5rem;
    `}
  `)}
`

const StyledLinkContainer = styled.div`
  margin-top: 1.5rem;
  border-radius: 0.25rem;
  background-color: ${theme.colors.lightGray};
  padding: 1rem;
  width: max-content;
  height: max-content;
`

const StyledIcon = styled(PdfIcon)`
  margin-right: 0.75rem;
  vertical-align: middle;
`

const SectionDownloads = ({dark, mobileVisible, documents, ...props}) => {
  return (
    <StyledDownloadContainer dark={dark} mobileVisible={mobileVisible} {...props}>
      <If condition={!isEmpty(documents?.title)}>
        <Typography variant="h3" type="h6">{documents?.title}</Typography>
      </If>
      <If condition={!isEmpty(documents?.files)}>
        <For each="file" of={documents?.files} index="idx">
          <StyledLinkContainer key={idx}>
            <BaseLink small normal to={file.file?.publicURL} isExternal><StyledIcon />{file?.fileName}</BaseLink>
          </StyledLinkContainer>
        </For>
      </If>
    </StyledDownloadContainer>
  )
}

SectionDownloads.propTypes = {
  dark: PropTypes.bool,
  mobileVisible: PropTypes.bool,
  documents: PropTypes.object,
}

export default SectionDownloads
