import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {For} from 'babel-plugin-jsx-control-statements'
import {useState} from 'react'
import {media} from '../../../utils'
import Typography from '../../atoms/Typography'
import Transition from '../../atoms/Transition'


const StyledHexagonSlider = styled.div`
  display: flex;
  flex-direction: row;
  margin: 3rem 0 5rem;

  ${media.down('mobile')(css`
    flex-direction: column;
    align-items: center;
    margin: 3rem 1.5rem;
  `)}
`

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3.75rem;
  padding: 0 10rem 0 14rem;
  width: 100%;
  max-width: 50%;
  text-align: start;

  ${media.down('mobile')(css`
    order: 1;
    margin-top: 0;
    padding: 0;
    max-width: 100%;
  `)}
`

const StyledSliderWrapper = styled.div`
  padding-bottom: 4rem;

  ${media.down('mobile')(css`
    padding-bottom: 1.5rem;
  `)}
`

const StyledProgressIndicatorContainer = styled.div`
  position: relative;
  margin-bottom: 3rem;
`

const StyledProgressIndicator = styled.span`
  display: flex;
  position: absolute;
  left: ${({left}) => left}rem;
  align-items: center;
  justify-content: center;
  transition:
    background-color ${({theme}) => theme.global.duration} ease-in,
    color ${({theme}) => theme.global.duration} ease-in;
  border: ${({theme, isActive}) => isActive ? '0' : `1px solid ${theme.colors.gray89}`};
  border-radius: 50%;
  background-color: ${({theme, isActive}) => isActive ? theme.colors.primary : theme.colors.background};
  cursor: pointer;
  width: 3rem;
  min-width: 3rem;
  height: 3rem;
  min-height: 3rem;
  color: ${({theme, isActive}) => isActive ? theme.colors.textInverted : theme.colors.text};

  &:not(:first-child) {
    &::before {
      position: absolute;
      right: 3rem;
      border: 1px solid ${({theme}) => theme.colors.border};
      width: 2.9rem;
      content: '';
    }
  }
`

const StyledTitle = styled(Typography)`
  transition: color ${({theme}) => theme.global.duration} ease-in;
  padding-bottom: 0.5rem;
`

const StyledDescription = styled(Typography)`
  padding: 1.5rem 0 2rem;
`

const StyledHexagonsContainer = styled.div`
  display: flex;
  position: relative;

  ${media.down('mobile')(css`
    width: 100%;
    min-height: 20rem;
    margin: 3rem 0;
  `)}
`

const StyledButtonContainer = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  transform: translate(${({left, top}) => `${left}, ${top}`});
  transition: ackground-color ${({theme}) => theme.global.duration} ease-in;
  margin-top: -5rem;
  background-color: transparent;
  width: calc(17rem + 2px);
  height: calc(15rem + 2px);

  &::before {
    position: absolute;
    transition: ackground-color ${({theme}) => theme.global.duration} ease-in;
    z-index: -1;
    background-color: ${({isActive, theme}) => isActive ? theme.colors.gray89 : theme.colors.border};
    width: calc(17rem + 2px);
    height: calc(15rem + 2px);
    content: '';
    clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
    ${media.down('mobile')(css`
      width: calc(10.5rem + 2px);
      height: calc(8.5rem + 2px);
    `)}
  }

  &::after {
    position: absolute;
    transition: ackground-color ${({theme}) => theme.global.duration} ease-in;
    z-index: -1;
    background-color: ${({white, theme}) => white ? theme.colors.background : theme.colors.lotionGray};
    width: 17rem;
    height: 15rem;
    content: '';
    clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);

    ${({isActive}) => isActive && css`
      background-color: ${({theme}) => theme.colors.gray98};
    `}

    ${media.down('mobile')(css`
      width: 10.5rem;
      height: 8.5rem;
    `)}
  }

  ${media.down('mobile')(css`
    width: calc(10.5rem + 2px);
    height: calc(8.5rem + 2px);
    left: 50%;
    transform: translate(${({mobileLeft, top}) => `${mobileLeft}, ${top}`});
  `)}
`

const StyledButton = styled.button`
  border: 0;
  background-color: transparent;
  cursor: ${({clickable}) => clickable ? 'pointer' : 'default'};
  width: 17rem;
  height: 15rem;
`

const StyledNumber = styled.span`
  display: flex;
  position: absolute;
  top: ${({top}) => top};
  left: ${({left}) => left};
  align-items: center;
  justify-content: center;
  transition:
    background-color ${({theme}) => theme.global.duration} ease-in,
    color ${({theme}) => theme.global.duration} ease-in;
  border: ${({theme, isActive}) => isActive ? '0' : `1px solid ${theme.colors.gray89}`};
  border-radius: 50%;
  background-color: ${({theme, isActive}) => isActive ? theme.colors.primary : theme.colors.background};
  cursor: pointer;
  width: 3rem;
  min-width: 3rem;
  height: 3rem;
  min-height: 3rem;
  color: ${({theme, isActive}) => isActive ? theme.colors.textInverted : theme.colors.text};

  ${media.down('mobile')(css`
    left: ${({mobileLeft}) => mobileLeft};
  `)}
`

const positions = [
  {
    top: '50%',
    left: '75%',
    mobileLeft: '-50%',
  },
  {
    top: '100%',
    left: '0%',
    mobileLeft: '-125%',
    numberTop: 'calc(25% - 1.5rem)',
    numberLeft: 'calc(70% + 1.5rem)',
    mobileNumberLeft: 'calc(60% + 1.5rem)',
  },
  {
    top: '150%',
    left: '75%',
    mobileLeft: '-50%',
    numberTop: '-1.5rem',
    numberLeft: 'calc(50% - 1.5rem)',
    mobileNumberLeft: 'calc(50% - 1.5rem)',
  },
  {
    top: '100%',
    left: '150%',
    mobileLeft: '25%',
    numberTop: 'calc(25% - 1.5rem)',
    numberLeft: 'calc(-5% + 1.5rem)',
    mobileNumberLeft: 'calc(-15% + 1.5rem)',
  },
]

const HexagonSlider = ({title, slides, ...props}) => {
  const [activeSlide, setActiveSlide] = useState(0)

  return (
    <StyledHexagonSlider {...props}>
      <StyledContentWrapper>
        <StyledSliderWrapper>
          <StyledProgressIndicatorContainer>
            <For each="slide" of={slides} index="idx">
              <StyledProgressIndicator
                  isActive={idx === activeSlide}
                  left={idx * 6}
                  onClick={() => setActiveSlide(idx)}
                  key={idx}
              >
                <Typography type="span" bold>{idx + 1}</Typography>
              </StyledProgressIndicator>
            </For>
          </StyledProgressIndicatorContainer>
        </StyledSliderWrapper>
        <Transition transitionKey={activeSlide}>
          <StyledTitle color="primary" variant="h2" type="h5">{slides[activeSlide].title}</StyledTitle>
        </Transition>
        <Transition transitionKey={activeSlide}>
          <Typography variant="h2" type="h5">{slides[activeSlide].subtitle}</Typography>
        </Transition>
        <Transition transitionKey={activeSlide}>
          <StyledDescription variant="p">{slides[activeSlide].description}</StyledDescription>
        </Transition>
      </StyledContentWrapper>
      <StyledHexagonsContainer>
        <StyledButtonContainer
            white
            top={positions[0].top}
            left={positions[0].left}
            mobileLeft={positions[0].mobileLeft}
        >
          <StyledButton>
            <StyledTitle color="secondary" variant="h2" type="h4">{title}</StyledTitle>
          </StyledButton>
        </StyledButtonContainer>
        <For each="slide" of={slides} index="idx">
          <StyledButtonContainer
              isActive={idx === activeSlide}
              top={positions[idx + 1].top}
              left={positions[idx + 1].left}
              mobileLeft={positions[idx + 1].mobileLeft}
              key={idx}
          >
            <StyledButton clickable onClick={() => setActiveSlide(idx)}>
              <StyledNumber
                  isActive={idx === activeSlide}
                  top={positions[idx + 1].numberTop}
                  left={positions[idx + 1].numberLeft}
                  mobileLeft={positions[idx + 1].mobileNumberLeft}
              >
                <Typography type="span" bold>{idx + 1}</Typography>
              </StyledNumber>
              <StyledTitle color={activeSlide === idx ? 'primary' : 'secondary'} variant="h3" type="h6">
                {slide.title}
              </StyledTitle>
            </StyledButton>
          </StyledButtonContainer>
        </For>
      </StyledHexagonsContainer>
    </StyledHexagonSlider>
  )
}

HexagonSlider.propTypes = {
  title: PropTypes.string.isRequired,
  slides: PropTypes.array.isRequired,
}

export default HexagonSlider
