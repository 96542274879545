import {graphql} from 'gatsby'
import PropTypes from 'prop-types'
import {useState} from 'react'
import {map, mapValues, isEmpty} from 'lodash-es'
import {If, For} from 'babel-plugin-jsx-control-statements'
import Section from '../components/containers/Section'
import Typography from '../components/atoms/Typography'
import AppShell from '../components/containers/AppShell'
import useLang from '../hooks/useLang'
import ReferenceRelation from '../components/molecules/ReferenceRelation'
import Navbar from '../components/containers/Navbar'
import SideTitleSection from '../components/containers/SideTitleSection'
import ServiceHero from '../components/molecules/ServiceHero'
import SectionDownload from '../components/molecules/ReferenceDetail/SectionDownload'
import SectionCallToAction from '../components/molecules/SectionCallToAction'
import PillarCard from '../components/molecules/Suite/PillarCard'
import PillarCardContainer from '../components/molecules/Suite/PillarCardContainer'
import HexagonSlider from '../components/molecules/Suite/HexagonSlider'
import TableSection from '../components/molecules/Suite/TableSection'
import DetailsSection from '../components/molecules/Suite/DetailsSection'
import BlurredModal from '../components/molecules/Suite/lazy'
import SuiteForm from '../components/molecules/Suite/SuiteForm'
import SSRSuspense from '../components/SSRSuspense/SSRSuspense'


const Suite = ({data, pageContext: {lang: pageLang}}) => {
  const [isOpen, setOpen] = useState(false)
  const [pillar, setPillar] = useState()
  const langToSlug = mapValues(data?.markdownRemark.frontmatter, 'slug')
  const {lang} = useLang(pageLang, langToSlug)
  const pageData = data?.markdownRemark.frontmatter[lang]
  const references = map(data?.references.edges, ({node}) => node.frontmatter[lang])

  const handleClose = () => setOpen(false)
  const handleOpen = (idx) => {
    setOpen(true)
    setPillar(idx)
  }

  return (
    <>
      <AppShell title={pageData.menuText} lang={lang} langToSlug={langToSlug} seo={pageData?.seo}>
        <Navbar
            title={pageData.menuText}
            lang={lang}
            langToSlug={langToSlug}
        />
        <ServiceHero
            title={pageData.hero.title}
            subTitle={pageData.hero.subtitle}
            description={pageData.hero.description}
            providers={pageData.hero.providers}
            tags={pageData.hero.tags}
            tagsText={pageData.hero.tagsText}
            image={pageData.hero.featuredImage}
        />
        <main>
          <Typography type="h1" srOnly>{pageData?.title}</Typography>
          <SideTitleSection
              title={pageData.tiers.title}
              fadedSideText={pageData.tiers.sideText}
              mobileTitleVisible
              mobileFadedSideText
          >
            <TableSection tiers={pageData.tiers} />
            <DetailsSection tiers={pageData.tiers} />
          </SideTitleSection>
          <SideTitleSection
              title={pageData.schema.title}
              fadedSideText={pageData.schema.sideText}
              mobileTitleVisible
              mobileFadedSideText
          >
            <HexagonSlider title={pageData.schema.hexagonTitle} slides={pageData.schema.hexagonSlides} />
          </SideTitleSection>
          <If condition={!isEmpty(pageData.documents?.files)}>
            <SectionDownload dark mobileVisible documents={pageData.documents} />
          </If>
          <Section>
            <For each="card" of={pageData.pillars} index="idx">
              <PillarCardContainer key={idx}>
                <PillarCard
                    title={card.title}
                    subtitle={card.subtitle}
                    description={card.description}
                    content={card.content}
                    featuredImage={card.featuredImage}
                    action={card.action}
                    onClick={() => handleOpen(idx)}
                    key={idx}
                />
              </PillarCardContainer>
            </For>
          </Section>
          <SectionCallToAction
              mobileCTAVisible
              onClick={() => handleOpen(pageData.pillars.length)}
              linkText={pageData.callToAction.linkText}
          >
            {pageData.callToAction.text}
          </SectionCallToAction>
          <SideTitleSection
              title={pageData.references.title}
              mobileTitleVisible
              mobileFadedSideText
              fadedSideText={pageData.references.sideText}
          >
            <ReferenceRelation
                lang={lang}
                logos={pageData.references.logos}
                allReferencesText={pageData.references.allReferencesText}
                allReferencesLink={pageData.references.allReferencesLink}
                references={references}
            />
          </SideTitleSection>
        </main>
      </AppShell>
      <SSRSuspense fallback={<div />}>
        <BlurredModal open={isOpen} onClose={handleClose}>
          <SuiteForm formContent={pageData.form} pillar={pillar} lang={lang} />
        </BlurredModal>
      </SSRSuspense>
    </>
  )
}

export const data = graphql`
  query Suite($id: String!, $references: [String!], $services: [String!]) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        ...SuitePageFragment
      }
    }

    services: allMarkdownRemark (
      filter: {frontmatter: {cs: {slug: {in: $services}}}}
    ) {
      edges {
        node {
          frontmatter {
            ...ServicePageFragment
          }
        }
      }
    }

    references: allMarkdownRemark (
      filter: {frontmatter: {cs: {slug: {in: $references}}}}
    ) {
      edges {
        node {
          frontmatter {
            ...ReferencePageFragment
          }
        }
      }
    }
  }
`

Suite.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    lang: PropTypes.string.isRequired,
  }).isRequired,
}

export default Suite
