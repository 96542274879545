import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {For} from 'babel-plugin-jsx-control-statements'
import theme from '../../../theme/theme'
import {media} from '../../../utils'
import CrossIcon from '../../../assets/icons/close-icon.svg'
import ColorFeatherCheckIcon from '../../atoms/Icons/ColorFeatherCheckIcon'
import Typography from '../../atoms/Typography'


const StyledTable = styled.table`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  margin: 3rem 10rem 6rem 14rem;
  background-color: ${theme.colors.background};
  overflow: scroll;

  ${media.down('mobile')(css`
    display: none;
  `)}
`

const StyledContent = styled.span`
  display: flex;
  justify-content: space-between;
  margin-bottom: 3.75rem;
`

const StyledSideHeadsContainer = styled.div`
  margin-top: 10rem;
  margin-bottom: 1.5rem;
  border: 1px solid ${theme.colors.border};
  border-radius: 3px 0 0 3px;
  background-color: ${theme.colors.gray98};
  padding: 0 2rem;
  width: 30%;
`

const StyledRowSection = styled.span`
  display: block;
  padding: 1.5rem 0 1rem;

  &:not(:last-child) {
    border-bottom: 1px solid ${theme.colors.border};
  }
`

const StyledHead = styled.thead`
  width: 100%;
`

const StyledSideHead = styled.th`
  text-align: left;
`

const StyledShadowContainer = styled.div`
  margin: 3rem 3rem 0 0;
  border-radius: 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
  padding: 2rem 2rem 0.5rem 0.5rem;
  width: 100%;
`

const StyledAnswersContainer = styled.div`
  background-color: ${theme.colors.background};
  padding: 0 2rem;
`

const StyledHeaderRowContainer = styled.tr`
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
`

const StyledTableHeader = styled.th`
  display: flex;
  flex-direction: column;
  width: 33.33%;
  text-align: center;
`

const StyledBody = styled.tbody`
  display: inline-block;
  width: 100%;
`

const StyledRowContainer = styled.tr`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  height: 3rem;
`

const StyledCell = styled.td`
  display: flex;
  justify-content: center;
  width: 33.33%;
`

const StyledAnswer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${({active}) => active ? `1px solid ${theme.colors.green}` : `1px solid ${theme.colors.silver}`};
  border-radius: 50%;
  width: 1.75rem;
  height: 1.75rem;
`

const StyledCrossIcon = styled(CrossIcon)`
  min-width: 2rem;

  path,
  g {
    stroke: ${theme.colors.silver};
  }
`


const TableSection = ({tiers}) => {
  return (
    <StyledTable>
      <StyledContent>
        <StyledSideHeadsContainer>
          <StyledRowSection>
            <StyledHead>
              <For each="point" of={tiers.sales.points} index="idx">
                <StyledRowContainer key={idx}>
                  <StyledSideHead>
                    {point.point}
                  </StyledSideHead>
                </StyledRowContainer>
              </For>
            </StyledHead>
          </StyledRowSection>
          <StyledRowSection>
            <StyledHead>
              <For each="point" of={tiers.automation.points} index="idx">
                <StyledRowContainer key={idx}>
                  <StyledSideHead>
                    {point.point}
                  </StyledSideHead>
                </StyledRowContainer>
              </For>
            </StyledHead>
          </StyledRowSection>
          <StyledRowSection>
            <StyledHead>
              <For each="point" of={tiers.nzu.points} index="idx">
                <StyledRowContainer key={idx}>
                  <StyledSideHead>
                    {point.point}
                  </StyledSideHead>
                </StyledRowContainer>
              </For>
            </StyledHead>
          </StyledRowSection>
        </StyledSideHeadsContainer>
        <StyledShadowContainer>
          <StyledAnswersContainer>
            <StyledHeaderRowContainer>
              <StyledTableHeader>
                <Typography variant="h2" type="h6" color="primary">{tiers.sales.title}</Typography>
                <Typography variant="h3" type="h5">{tiers.sales.focus}</Typography>
              </StyledTableHeader>
              <StyledTableHeader>
                <Typography variant="h2" type="h6" color="primary">{tiers.automation.title}</Typography>
                <Typography variant="h3" type="h5">{tiers.automation.focus}</Typography>
              </StyledTableHeader>
              <StyledTableHeader>
                <Typography variant="h2" type="h6" color="primary">{tiers.nzu.title}</Typography>
                <Typography variant="h3" type="h5">{tiers.nzu.focus}</Typography>
              </StyledTableHeader>
            </StyledHeaderRowContainer>
            <StyledBody>
              <StyledRowSection>
                <For each="point" of={tiers.sales.points} index="idx">
                  <StyledRowContainer key={idx}>
                    <StyledCell>
                      <StyledAnswer active>
                        <ColorFeatherCheckIcon color={theme.colors.green} />
                      </StyledAnswer>
                    </StyledCell>
                    <StyledCell >
                      <StyledAnswer>
                        <StyledCrossIcon />
                      </StyledAnswer>
                    </StyledCell>
                    <StyledCell>
                      <StyledAnswer>
                        <StyledCrossIcon />
                      </StyledAnswer>
                    </StyledCell>
                  </StyledRowContainer>
                </For>
              </StyledRowSection>
              <StyledRowSection>
                <For each="point" of={tiers.automation.points} index="idx">
                  <StyledRowContainer key={idx}>
                    <StyledCell>
                      <StyledAnswer>
                        <StyledCrossIcon />
                      </StyledAnswer>
                    </StyledCell>
                    <StyledCell>
                      <StyledAnswer active>
                        <ColorFeatherCheckIcon color={theme.colors.green} />
                      </StyledAnswer>
                    </StyledCell>
                    <StyledCell>
                      <StyledAnswer>
                        <StyledCrossIcon />
                      </StyledAnswer>
                    </StyledCell>
                  </StyledRowContainer>
                </For>
              </StyledRowSection>
              <StyledRowSection>
                <For each="point" of={tiers.nzu.points} index="idx">
                  <StyledRowContainer key={idx}>
                    <StyledCell>
                      <StyledAnswer>
                        <StyledCrossIcon />
                      </StyledAnswer>
                    </StyledCell>
                    <StyledCell>
                      <StyledAnswer>
                        <StyledCrossIcon />
                      </StyledAnswer>
                    </StyledCell>
                    <StyledCell>
                      <StyledAnswer active>
                        <ColorFeatherCheckIcon color={theme.colors.green} />
                      </StyledAnswer>
                    </StyledCell>
                  </StyledRowContainer>
                </For>
              </StyledRowSection>
            </StyledBody>
          </StyledAnswersContainer>
        </StyledShadowContainer>
      </StyledContent>
    </StyledTable>
  )
}

TableSection.propTypes = {
  tiers: PropTypes.object.isRequired,
}

export default TableSection
