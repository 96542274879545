import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {FeatherCheckColorableIcon} from '../../../../assets/icons'


const StyledIcon = styled(FeatherCheckColorableIcon)`
  ${(props) => css`
  stroke: ${props.strokecolor};
  `};
`

const ColorFeatherCheckIcon = ({color, ...props}) => {
  return (
    <StyledIcon strokecolor={color} {...props} />
  )
}

ColorFeatherCheckIcon.defaultProps = {
  color: 'blue',
}

ColorFeatherCheckIcon.propTypes = {
  color: PropTypes.string,
}

export default ColorFeatherCheckIcon
