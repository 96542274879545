import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {For} from 'babel-plugin-jsx-control-statements'
import {media, convertMDContent} from '../../../utils'
import Typography from '../../atoms/Typography'
import ImageContainer from '../Details/ImageContainer'


const StyledServiceContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 6.125rem;

  ${media.down('mobile')(css`
    margin: 1.55rem;
    order: 0;
  `)}
`

const StyledTitle = styled(Typography)`
  grid-column: 1 / 2;
  line-height: 1.75;
  font-size: 2.5rem;
  font-weight: bold;

  p {
    line-height: 1.75;
  }

  ${media.down('mobile')(css`
    grid-column: 1 / 3;
    margin-top: 2rem;
    order: 0;
  `)}
`

const StyledSubtitle = styled(Typography)`
  grid-row: 2 / 3;
  grid-column: 1 / 2;
  margin-top: 4rem;

  ${media.down('mobile')(css`
    grid-column: 1 / 3;
    order: 1;
    margin-top: 3.125rem;
  `)}
`

const StyledDescription = styled(Typography)`
  grid-column: 1 / 2;
  margin-top: 2.5rem;

  ${media.down('mobile')(css`
    grid-column: 1 / 3;
    order: 3;
    margin-top: 5rem;
  `)}
`

const StyledTagsContainer = styled.div`
  display: flex;
  grid-column: 1 / 2;
  margin-top: 4rem;

  ${media.down('mobile')(css`
    grid-column: 1 / 3;
    flex-direction: column;
    order: 4;
    margin-top: 3.75rem;
  `)}
`

const StyledTagsText = styled(Typography)`
  margin-right: 4rem;
`

const StyledTagContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border-left: 1px solid rgba(49, 49, 49, 0.1);
  padding-left: 1.25rem;

  ${media.down('mobile')(css`
    margin: 1.5rem 0;
  `)}
`

const StyledTag = styled(Typography)`
  padding: 0 0.75rem;
  height: calc(100%/3);

  ${media.down('mobile')(css`
    height: auto;
    padding-bottom: 0.5rem;
  `)}
`

const ServiceHero = ({title, subTitle, description, tagsText, tags, image, ...props}) => {
  return (
    <StyledServiceContainer {...props}>
      <StyledTitle type="div" dangerouslySetInnerHTML={{__html: convertMDContent(title)}} />
      <StyledSubtitle variant="h2" type="h5" thin>{subTitle}</StyledSubtitle>
      <StyledDescription>{description}</StyledDescription>
      <StyledTagsContainer>
        <StyledTagsText variant="h3" type="span" bold>{tagsText}</StyledTagsText>
        <StyledTagContainer>
          <For each="tag" of={tags} index="idx">
            <StyledTag type="span" key={idx}>{tag}</StyledTag>
          </For>
        </StyledTagContainer>
      </StyledTagsContainer>
      <ImageContainer image={image} />
    </StyledServiceContainer>
  )
}

ServiceHero.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  tagsText: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
  image: PropTypes.object.isRequired,
}

export default ServiceHero
