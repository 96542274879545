import PropTypes from 'prop-types'
import styled from 'styled-components'
import BaseLink from '../BaseLink'


const StyledButton = styled('button')`
  display: flex;
  position: relative;
  align-items: center;
  border: 0;
  border-radius: 0.24rem;
  background: ${({theme}) => theme.colors.primary};
  cursor: pointer;
  padding: 1.35rem 3.2rem;
  width: fit-content;
  line-height: 1.625rem;
  letter-spacing: 0.05em;
  color: ${({theme}) => theme.colors.textInverted};
  font-size: 1rem;
  font-weight: ${({theme}) => theme.components.typography.weight.semiBold};

  &.theme--light {
    background-color: ${({theme}) => theme.colors.white};
    color: ${({theme}) => theme.colors.primary};
  }
`

const SecondaryButton = ({to, onClick, theme, children, ...props}) => {
  const as = to ? BaseLink : 'button'

  return (
    <StyledButton
        as={as}
        to={to}
        onClick={onClick}
        className={`theme--${theme}`}
        {...props}
    >
      {children}
    </StyledButton>
  )
}

SecondaryButton.defaultProps = {
  theme: 'dark',
}

SecondaryButton.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
  theme: PropTypes.oneOf(['dark', 'light']),
  children: PropTypes.node.isRequired,
}

export default SecondaryButton
